import { graphql } from 'gatsby'
import * as React from 'react'

import { Layout } from '../components/Layout'
import { SliceZone } from '@prismicio/react'
import { components } from '../slices'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import EnquiryForm from '../components/EnquiryForm'
import CharityMap from '../components/CharityMap'

const Home = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHome || {}

  return (
    <Layout>
      <SliceZone slices={homepage.data.body} components={components} />
      <CharityMap />
      <div className="bg-dark-green pt-8 pb-4 lg:pb-12">
        <div className="container mx-auto px-4">
          <EnquiryForm />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery {
    prismicHome {
      _previewable
      url
      type
      data {
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...HomeDataBodyExtraMileBanner
          ...HomeDataBodyFullWidth2Col
        }
      }
    }
  }
`

export default withPrismicPreview(Home)

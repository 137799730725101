import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

const SEO = ({ title, description }) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const seoProps = {
    title: title || queryData.site.siteMetadata.title,
    description: description || queryData.site.siteMetadata.description,
  }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{seoProps.title}</title>
      <html lang="en" />
      <meta name="description" content={seoProps.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
  )
}

export default SEO

import { PrismicRichText } from '@prismicio/react'
import { graphql } from 'gatsby'
import React from 'react'

import Ambulance from '../images/ambulance.png'

export const ExtraMileBanner = ({ slice }) => {
  return (
    <section className="extra-mile-banner bg-dark-green pb-6 relative lg:pb-10 z-0">
      <div className="lg:container mx-auto px-4 relative">
        {/* Cloud */}
        <div className="absolute top-0 right-0 lg:z-10" aria-hidden>
          <img
            alt=""
            loading="lazy"
            src={`/images/cloud.svg`}
            className="mt-4 object-contain object-center hidden lg:block lg:w-24"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 lg:grid-flow-row">
          <div className="bg-light-blue -mx-4 px-4 pb-20 lg:bg-transparent lg:p-0 lg:mx-0 lg:col-span-7  lg:z-0">
            <div className="font-display text-md-3 text-white leading-none max-w-lg lg:max-w-none lg:mt-6 md:text-xl-1 lg:text-md-5 md:leading-none lg:relative xl:text-xl-1 2xl:text-2xl-1 lg:pb-3">
              {/* Blue background on desktop */}
              <div
                className="w-[200vw] -left-1/2 bottom-0 top-0 absolute bg-light-blue -z-[1] hidden lg:block -mt-6 -mb-6"
                aria-hidden
              ></div>
              {/* Heading */}
              <PrismicRichText field={slice.primary.main_heading.richText} />
            </div>
          </div>
          {/* Ambulance */}
          <div className="-mt-14 mx-auto w-3/4 lg:w-auto z-10 lg:col-span-5 lg:row-span-2 lg:mt-1 lg:-mr-4 xl:-mr-1 flex justify-center">
            <img
              src={slice.primary.image.url}
              alt={slice.primary.image.alt}
              loading="lazy"
              className="object-contain object-top"
            />
          </div>
          {/* Optional description */}
          {slice.primary.description?.richText.length > 0 && (
            <div className="text-white mt-10 text-xs-2 lg:row-start-2 lg:mt-16 lg:col-span-7 max-w-prose mx-auto lg:mx-0 lg:text-xs-2 xl:text-sm-1">
              <PrismicRichText field={slice.primary.description.richText} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyExtraMileBanner on PrismicPageDataBodyExtraMileBanner {
    primary {
      description {
        richText
      }
      image {
        alt
        url
      }
      main_heading {
        richText
      }
    }
  }
  fragment HomeDataBodyExtraMileBanner on PrismicHomeDataBodyExtraMileBanner {
    primary {
      description {
        richText
      }
      image {
        alt
        url
      }
      main_heading {
        richText
      }
    }
  }
`

import React, { useState, useEffect, useRef } from 'react'
import debounce from '../utils/debounce'
import axios from 'axios'

const SvgComponent = ({
  hoverFn,
  clickFn,
  highlightedArea,
  setHighlightedArea,
}) => {
  const svgContainer = useRef(null)

  const [mapSvgHtml, setMapSvgHtml] = useState('')

  const idsToIgnore = ['mapSvg', 'Base_Map']

  const getClosestId = (target) => {
    let id = ''
    let element = null
    if (
      target.tagName === 'path' ||
      target.tagName === 'g' ||
      target.tagName === 'polyline' ||
      target.tagName === 'polygon'
    ) {
      if (!target.id) {
        element = target.closest(['[id]'])
        id = element?.id
      } else {
        element = target
        id = target.id
      }
      if (idsToIgnore.includes(id) || !id) {
        id = ''
        element = null
      }
    }

    return [id, element]
  }

  // Async load SVG
  useEffect(() => {
    async function createMarkup() {
      try {
        const response = await axios.get('/images/map.svg')
        const svgHtml = response.data
        setMapSvgHtml(svgHtml)
      } catch (error) {
        console.log(error)
      }
    }
    createMarkup()
  }, [])

  const handleMouseEnter = debounce((e) => {
    if (window.innerWidth < 1024) return
    if (e.target.tagName === 'svg') {
      hoverFn('')
      if (highlightedArea) {
        highlightedArea.classList.remove('highlight')
      }
      return
    }
    const [id, element] = getClosestId(e.target)

    if (id && id !== 'Layer_1') {
      if (highlightedArea) {
        highlightedArea.classList.remove('highlight')
      }
      element.classList.add('highlight')
      setHighlightedArea(element)
    } else {
      if (highlightedArea) {
        highlightedArea.classList.remove('highlight')
      }
      setHighlightedArea(null)
    }

    hoverFn(id)
  }, 25)

  const handleOnClick = (e) => {
    const [id, element] = getClosestId(e.target)

    setHighlightedArea(element)

    if (highlightedArea) {
      highlightedArea.classList.remove('highlight')
    }
    if (element) {
      element.classList.add('highlight')
    }

    clickFn(id)
  }

  return (
    <div
      id="mapSvgContainer"
      ref={svgContainer}
      className="w-screen h-full lg:mx-10 relative"
      onMouseMove={handleMouseEnter}
      onClick={handleOnClick}
      dangerouslySetInnerHTML={{ __html: mapSvgHtml }}
    ></div>
  )
}

export default React.memo(SvgComponent)

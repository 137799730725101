import React, { useState } from 'react'

import { graphql, Link, useStaticQuery } from 'gatsby'

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const query = useStaticQuery(graphql`
    {
      prismicNavigation {
        data {
          links {
            link {
              url
            }
            link_name {
              text
            }
          }
        }
      }
    }
  `)
  const queryData = query.prismicNavigation.data

  const navLinks = queryData.links

  return (
    <header
      className={`w-full flex bg-light-blue lg:bg-dark-blue py-6 lg:pt-2 lg:pb-4 lg:leading-normal z-20 fixed top-0`}
    >
      {/* Mobile header */}
      <div className="w-full flex justify-between items-center px-4 lg:hidden">
        {/* Cloud / Home link (mobile) */}
        <div>
          <Link to="/">
            <img
              alt="Cloud"
              src={`/images/cloud.svg`}
              height={19}
              className="object-contain object-center"
            />
          </Link>
        </div>
        {/* Open mobile menu btn */}
        <div>
          <button
            type="button"
            aria-label="open mobile menu"
            onClick={() => {
              setMobileMenuOpen(true)
            }}
          >
            <img
              alt="Mobile Menu"
              src={`/images/menu.svg`}
              height={24}
              className="object-contain object-center"
            />
          </button>
        </div>
      </div>
      {/* Navigation */}
      <nav
        className={`bg-dark-blue w-[90%] translate-x-full z-20 ${
          mobileMenuOpen && `translate-x-0`
        } lg:translate-x-0 transition-transform duration-300 lg:transition-none lg:w-full absolute right-0 top-0 bottom-0 lg:relative p-6 h-screen lg:h-auto overflow-y-auto lg:p-0`}
      >
        {/* Close button (mobile) */}
        <button
          type="button"
          aria-label="close mobile menu"
          onClick={() => {
            setMobileMenuOpen(false)
          }}
          className="fixed top-0 right-0 mt-6 mr-6 lg:hidden"
        >
          <img
            alt="Close"
            src={`/images/close.svg`}
            height={24}
            className="object-contain object-center"
          />
        </button>
        {/* Links */}
        <ul className="flex items-center justify-center flex-col space-y-8 lg:flex-row mt-32 pb-6 lg:pb-0 lg:mt-0 lg:justify-start lg:container lg:px-4 lg:mx-auto lg:space-y-0 lg:space-x-11">
          {navLinks.map((nav, idx) => {
            const { link, link_name } = nav
            return (
              <li key={`link-${idx}`}>
                <Link
                  className="uppercase text-white font-display text-md-2 lg:text-sm-2 underline-offset-[12px] lg:underline-offset-2 hover:underline"
                  activeClassName="underline"
                  to={link.url}
                >
                  {link_name.text}
                </Link>
              </li>
            )
          })}
        </ul>
      </nav>
    </header>
  )
}

export default Header

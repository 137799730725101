import React from 'react'

import Seo from './SEO'
import Header from './Header'
import Footer from './Footer'

export const Layout = ({ title = null, description = null, children }) => {
  return (
    <div className="min-h-screen">
      <Seo title={title} description={description} />
      <Header />
      <main
        className={`relative font-body w-full overflow-hidden pt-18 lg:pt-16`}
      >
        {children}
      </main>
      <Footer />
    </div>
  )
}

import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser'
import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

const EnquiryForm = () => {
  const form = useRef()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formIsSubmitting, setFormIsSubmitting] = useState(false)
  const labelClasses = `block text-xs-1 text-white px-3 lg:text-sm-1 lg:px-2`
  const inputClasses = `mt-1 focus:ring-2 focus:ring-light-blue focus:border-light-blue block w-full text-xs-1 py-3 px-3 lg:py-4 lg:px-5 placeholder:text-black/50 focus:outline-none focus-visible:outline-none`
  const errorClasses = `ring-2 ring-red`

  const [formState, setFormState] = useState({
    'first-name': '',
    'last-name': '',
    'email-address': '',
    'mobile-number': '',
    'enquiry-details': '',
  })

  const [errors, setErrors] = useState({
    'first-name': false,
    'last-name': false,
    'email-address': false,
    'mobile-number': false,
    'enquiry-details': false,
  })

  const [formIsValid, setFormIsValid] = useState(false)

  const validateEmail = (value) => {
    // Simple check, regex for email is unreliable
    return `${value}`.includes('@')
  }

  const validateMobileNumber = (value) => {
    try {
      const parseNum = phoneUtil.parse(value, 'GB')
      return phoneUtil.isValidNumber(parseNum)
    } catch (error) {
      return false
    }
  }

  const checkFieldIsValid = (name, value) => {
    let newErrors = { ...errors }
    let isValid = true

    switch (name) {
      case 'email-address':
        let emailValid = validateEmail(value)
        if (!emailValid) {
          isValid = false
          newErrors = { ...newErrors, 'email-address': true }
        } else {
          newErrors = { ...newErrors, 'email-address': false }
        }
        break
      case 'mobile-number':
        let phoneValid = validateMobileNumber(value)

        if (!phoneValid) {
          isValid = false
          newErrors = { ...newErrors, 'mobile-number': true }
        } else {
          newErrors = { ...newErrors, 'mobile-number': false }
        }
        break
      default:
        if (value.trim().length === 0) {
          isValid = false
          newErrors = { ...newErrors, [name]: true }
        } else {
          newErrors = { ...newErrors, [name]: false }
        }
        break
    }

    setErrors({ ...errors, ...newErrors })
    return newErrors
  }

  const checkFormIsValid = (state, newErrors) => {
    const noErrors = !Object.values(newErrors).includes(true)

    const isData = Object.values(state).reduce((isValid, fieldData) => {
      let isEmpty = false
      if (fieldData.trim().length === 0) {
        isEmpty = true
      }
      return isValid && !isEmpty
    }, true)

    setFormIsValid(noErrors && isData)
  }

  const onChange = (e) => {
    const newState = { ...formState, [e.target.name]: e.target.value }
    setFormState(newState)
    const newErrors = checkFieldIsValid(e.target.name, e.target.value)
    checkFormIsValid(newState, newErrors)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setFormIsSubmitting(true)

    emailjs
      .sendForm(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.GATSBY_EMAILJS_USER_ID,
      )
      .then(
        (result) => {
          setFormSubmitted(true)
          setFormIsSubmitting(false)
        },
        (error) => {
          setFormSubmitted(true)
          setFormIsSubmitting(false)
          console.log(error)
        },
      )
  }

  return (
    <div className="flex flex-col">
      <h2 className="font-display text-white mb-7 lg:text-lg-1 text-md-1">
        Get in touch
      </h2>

      <form onSubmit={onSubmit} ref={form}>
        <div className="relative overflow-hidden shadow-md shadow-black/25 lg:shadow-none rounded-xl">
          <div className="px-4 pt-7 pb-5 bg-dark-blue lg:px-20 lg:pt-14 lg:pb-8">
            <div className="grid grid-cols-2 gap-y-6 lg:gap-x-44 lg:gap-y-8">
              <div className="col-span-full lg:col-span-1">
                <label htmlFor="first-name" className={labelClasses}>
                  First name
                </label>
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className={
                    inputClasses +
                    `${errors['first-name'] && ` ${errorClasses}`}`
                  }
                  placeholder="E.g. John"
                  onChange={onChange}
                  value={formState['first-name']}
                />
              </div>

              <div className="col-span-full lg:col-span-1">
                <label htmlFor="last-name" className={labelClasses}>
                  Last name
                </label>
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className={
                    inputClasses +
                    `${errors['last-name'] && ` ${errorClasses}`}`
                  }
                  placeholder="E.g. Smith"
                  onChange={onChange}
                  value={formState['last-name']}
                />
              </div>

              {/* Email address */}
              <div className="col-span-full">
                <label htmlFor="email-address" className={labelClasses}>
                  Email address
                </label>
                <input
                  type="text"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  className={
                    inputClasses +
                    `${errors['email-address'] && ` ${errorClasses}`}`
                  }
                  placeholder="E.g. johnsmith01@gmail.com"
                  onChange={onChange}
                  value={formState['email-address']}
                />
              </div>

              {/* Mobile number */}
              <div className="col-span-full">
                <label htmlFor="mobile-number" className={labelClasses}>
                  Mobile number
                </label>
                <input
                  type="text"
                  name="mobile-number"
                  id="mobile-number"
                  autoComplete="tel"
                  className={
                    inputClasses +
                    `${errors['mobile-number'] && ` ${errorClasses}`}`
                  }
                  placeholder="E.g. 07123 456 789"
                  onChange={onChange}
                  value={formState['mobile-number']}
                />
              </div>

              {/* Enquiry details */}
              <div className="col-span-full">
                <label htmlFor="enquiry-details" className={labelClasses}>
                  Enquiry details
                </label>
                <textarea
                  type="text"
                  name="enquiry-details"
                  id="enquiry-details"
                  className={
                    inputClasses +
                    `${errors['enquiry-details'] && ` ${errorClasses}`}`
                  }
                  placeholder="Write your message here..."
                  rows={8}
                  onChange={onChange}
                  value={formState['enquiry-details']}
                />
              </div>
            </div>
            <div className=" lg:px-4 text-center mt-6">
              <button
                disabled={!formIsValid || formIsSubmitting}
                type="submit"
                className={`w-full lg:w-auto flex lg:inline-flex justify-center pt-2 pb-3 px-10 lg:px-24 lg:pt-3 lg:pb-4 font-display text-white bg-light-blue hover:bg-light-blue/75 focus:outline-none uppercase leading-normal text-xs-1 lg:text-[22px] ${
                  !formIsValid && `opacity-50 cursor-not-allowed`
                }`}
              >
                Send
              </button>
            </div>
          </div>
          {formSubmitted && (
            <div
              role="presentation"
              aria-describedby="enquiry-success"
              className={`absolute inset-0 translate-y-full transition-transform duration-700 flex justify-center items-center bg-dark-blue z-10 ${
                formSubmitted && `translate-y-0`
              }`}
            >
              <span
                id="enquiry-success"
                className="text-white font-display uppercase text-md-1 lg:text-lg-1 text-center leading-none"
              >
                Enquiry Sent Successfully!
              </span>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export default EnquiryForm

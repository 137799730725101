import React, { useState, useEffect } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import MapSvg from './MapSvg'
import PointerSvg from '../images/pointer.svg'
import BoxPlusSvg from '../images/box-plus.svg'
import BoxMinusSvg from '../images/box-minus.svg'

const locations = [
  {
    name: 'Yorkshire',
    link: 'https://www.justgiving.com/campaign/OutrunYAS',
    children: [
      {
        name: 'East',
        link: 'https://www.justgiving.com/campaign/OutrunYASEast',
      },
      {
        name: 'Bradford, Calderdale & Kirklees',
        link: 'https://www.justgiving.com/campaign/OutrunYASBCK',
      },
      {
        name: 'Leeds and Wakefield',
        link: 'https://www.justgiving.com/campaign/OutrunYASLandW',
      },
      {
        name: 'South',
        link: 'https://www.justgiving.com/campaign/OutrunYASSouth',
      },
      {
        name: 'North',
        link: 'https://www.justgiving.com/campaign/OutrunYASNorth',
      },
    ],
  },
  {
    name: 'Greater London',
    link: 'https://www.justgiving.com/campaign/outrunLAS2022',
  },

  {
    name: 'East of England',
    link: 'https://www.justgiving.com/campaign/OAAEEAST',
    children: [
      {
        name: 'Bedfordshire',
        link: 'https://www.justgiving.com/campaign/OAAEEAST',
      },
      {
        name: 'Cambridgeshire',
        link: 'https://www.justgiving.com/campaign/OAAEEAST',
      },
      { name: 'Essex', link: 'https://www.justgiving.com/campaign/OAAEEAST' },
      {
        name: 'Hertfordshire',
        link: 'https://www.justgiving.com/campaign/OAAEEAST',
      },
      { name: 'Norfolk', link: 'https://www.justgiving.com/campaign/OAAEEAST' },
      { name: 'Suffolk', link: 'https://www.justgiving.com/campaign/OAAEEAST' },
    ],
  },

  {
    name: 'North West',
    link: 'https://justgiving.com/campaign/oaanwas',
    children: [
      { name: 'Cumbria', link: 'https://justgiving.com/campaign/oaanwas' },
      { name: 'Lancashire', link: 'https://justgiving.com/campaign/oaanwas' },
      {
        name: 'Greater Manchester',
        link: 'https://justgiving.com/campaign/oaanwas',
      },
      { name: 'Merseyside', link: 'https://justgiving.com/campaign/oaanwas' },
      { name: 'Cheshire', link: 'https://justgiving.com/campaign/oaanwas' },
      { name: 'Glossop', link: 'https://justgiving.com/campaign/oaanwas' },
    ],
  },

  {
    name: 'South Central',
    link: 'https://www.justgiving.com/campaign/scasoutrunanambulance2022',
    children: [
      {
        name: 'Berkshire',
        link: 'https://www.justgiving.com/campaign/scasoutrunanambulance2022',
      },
      {
        name: 'Buckinghamshire',
        link: 'https://www.justgiving.com/campaign/scasoutrunanambulance2022',
      },
      {
        name: 'Oxfordshire',
        link: 'https://www.justgiving.com/campaign/scasoutrunanambulance2022',
      },
      {
        name: 'Hampshire',
        link: 'https://www.justgiving.com/campaign/scasoutrunanambulance2022',
      },
    ],
  },

  {
    name: 'South West',
    link: 'https://www.justgiving.com/campaign/oaasouthwest',
    children: [
      {
        name: 'South Gloucestershire',
        link: 'https://www.justgiving.com/campaign/oaabnssg',
      },
      {
        name: 'Gloucestershire',
        link: 'https://www.justgiving.com/campaign/oaagloucestershire',
      },
      {
        name: 'Wiltshire',
        link: 'https://www.justgiving.com/campaign/oaawiltshire',
      },
      {
        name: 'Bristol',
        link: 'https://www.justgiving.com/campaign/oaabnssg',
      },
      {
        name: 'North Somerset',
        link: 'https://www.justgiving.com/campaign/oaabnssg',
      },
      {
        name: 'Somerset',
        link: 'https://www.justgiving.com/campaign/oaasomerset',
      },
      {
        name: 'North & East Devon',
        link: 'https://www.justgiving.com/campaign/oaanedevon',
      },
      {
        name: 'South & West Devon',
        link: 'https://www.justgiving.com/campaign/oaaswdevon',
      },
      { name: 'Dorset', link: 'https://www.justgiving.com/campaign/oaadorset' },
      {
        name: 'Cornwall & Isles of Scilly',
        link: 'https://www.justgiving.com/campaign/oaacornwall',
      },
    ],
  },
]

const CharityMap = () => {
  const [locationDropdownIsVisible, setLocationDropdownIsVisible] =
    useState(false)

  const [hasInteracted, setHasInteracted] = useState(false)

  const [activeHoverRegion, setActiveHoverRegion] = useState('')

  const [isDesktop, setIsDesktop] = useState(false)

  const [mapObj, setMapObj] = useState(null)

  const [highlightedArea, setHighlightedArea] = useState(null)

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setIsDesktop(true)
    }
  }, [])

  useEffect(() => {
    const scale = isDesktop ? 0.8 : 1.25
    if (mapObj) {
      mapObj.setTransform(
        0,
        -window.innerWidth / 2 / 1.45,
        scale,
        300,
        'easeOut',
      )
    }
  }, [isDesktop])

  function findNode(name) {
    let link = ''
    locations.forEach((node) => {
      if (node.name === name) {
        link = node.link
      } else if (node.children) {
        node.children.forEach((nestedNode) => {
          if (nestedNode.name === name) {
            link = nestedNode.link
          }
        })
      }
    })

    return link
  }

  const onRegionHover = (regionText) => {
    if (regionText && regionText !== 'Layer_1') {
      setActiveHoverRegion(formatRegionName(regionText))
    } else {
      setActiveHoverRegion('')
    }
  }

  const formatRegionName = (region) => {
    let formatted = region
    formatted = formatted.replaceAll('_', ' ')
    formatted = formatted.replaceAll('and', '&')

    return formatted
  }

  const onRegionClick = (clickedRegion) => {
    const regionName = formatRegionName(clickedRegion)
    if (activeHoverRegion != '') {
      if (regionName === activeHoverRegion) {
        const searchTerm = formatRegionName(activeHoverRegion)
        const link = findNode(searchTerm, locations)

        if (link) {
          setActiveHoverRegion('')
          window.open(link, '_blank').focus()
        }
      }
    }
    setActiveHoverRegion(regionName)
  }

  const onMapInit = (ref) => {
    setMapObj(ref)
  }

  return (
    <section className="w-full py-6 bg-dark-green text-white flex flex-col justify-center">
      {/* Text */}
      <div className="container mx-auto flex flex-col px-4">
        <h2 className="font-display text-md-1 mb-4 leading-none lg:text-lg-1 lg:text-center lg:mb-6">
          Choose an area to support
        </h2>
        <p className="text-xs-1 font-bold mb-4 lg:text-sm-1 lg:text-center">
          Select one of the highlighted areas on the map or choose from the list
          below:
        </p>
        <small className="text-xs-1 mb-4 lg:text-sm-1 lg:text-center lg:mb-6">
          Donations received will go to the local Ambulance Charity for your
          chosen area.
        </small>
        {/* Dropdown */}
        <div className="w-full bg-white py-3 px-4 rounded-lg relative mb-4 lg:mx-auto lg:max-w-[580px] lg:mb-14 lg:px-6">
          <div
            role="dialog"
            aria-labelledby="location-selector"
            onClick={() => {
              setLocationDropdownIsVisible(!locationDropdownIsVisible)
            }}
            className="flex flex-row justify-between items-center cursor-pointer"
          >
            <span
              id="location-selector"
              className="font-black text-black text-xs-1"
            >
              Choose a location
            </span>{' '}
            <i>
              <img
                alt=""
                className={`object-container object-center w-5 h-3 transition-transform duration-500 ${
                  locationDropdownIsVisible && `rotate-180`
                }`}
                src={`images/caret-down.svg`}
              ></img>
            </i>
          </div>
          {/* Location Dropdown */}
          <div
            className={`bg-white text-black absolute top-full mt-3 p-6 rounded-lg shadow-lg shadow-black/25 w-full left-0 z-10 ${
              locationDropdownIsVisible ? `` : `hidden`
            }`}
          >
            <button
              aria-label="Close"
              onClick={() => {
                setLocationDropdownIsVisible(false)
              }}
              className="sr-only"
            >
              Close
            </button>
            <div
              className="overflow-y-auto max-h-80 scroll flex flex-col space-y-5 primary-scrollbar"
              onClick={() => {
                setLocationDropdownIsVisible(false)
              }}
            >
              {locations.map((location) => {
                return (
                  <ul key={location.name} className="">
                    <li>
                      <a
                        className="font-black text-xs-1"
                        href={location.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {location.name}
                      </a>
                    </li>
                    {location.children?.length > 0 && (
                      <li className="mt-2">
                        <ul className="flex flex-col space-y-4">
                          {location.children.map((nestedLocation) => {
                            return (
                              <li key={nestedLocation.name}>
                                <a
                                  className="text-xs-1 px-4 flex flex-wrap"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={nestedLocation.link}
                                >
                                  {nestedLocation.name}
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                      </li>
                    )}
                  </ul>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      {/* Map */}
      <div
        className="relative w-full h-[80vh] max-h-[1024px] lg:h-screen overflow-hidden"
        onTouchStart={() => {
          setHasInteracted(true)
        }}
        onMouseDown={() => {
          setHasInteracted(true)
        }}
      >
        <div className="absolute inset-0 z-0">
          <TransformWrapper
            maxScale={3}
            minScale={0.5}
            centerZoomedOut={false}
            limitToBounds={true}
            wheel={{ wheelDisabled: true }}
            onInit={onMapInit}
          >
            {({
              zoomIn,
              zoomOut,
              resetTransform,
              state: zoomState,
              instance,
              ...rest
            }) => (
              <>
                <TransformComponent wrapperClass="absolute inset-0 w-auto h-full-important z-0">
                  <MapSvg
                    hoverFn={onRegionHover}
                    clickFn={onRegionClick}
                    highlightedArea={highlightedArea}
                    setHighlightedArea={setHighlightedArea}
                  />
                </TransformComponent>
                {/* Controls */}
                <div className="absolute bottom-0 right-0 mb-20 mr-20 hidden lg:flex flex-col space-y-2 z-10 isolate">
                  <button
                    disabled={zoomState.scale === instance.setup.maxScale}
                    onClick={() => zoomIn()}
                    type="button"
                    role="button"
                    className="focus:bg-white/30"
                  >
                    <BoxPlusSvg className="stroke-white text-white w-10 h-10" />
                  </button>
                  <button
                    disabled={zoomState.scale === instance.setup.minScale}
                    onClick={() => zoomOut()}
                    type="button"
                    role="button"
                    className="focus:bg-white/30"
                  >
                    <BoxMinusSvg className="stroke-white text-white w-10 h-10 pointer" />
                  </button>
                </div>
              </>
            )}
          </TransformWrapper>
        </div>
        {/* Interaction instructions */}
        {!hasInteracted && (
          <div className="absolute inset-0 flex flex-col justify-center items-center pointer-events-none bg-black/30 text-white p-4 space-y-4">
            <PointerSvg className="object-contain object-center w-7 h-8 lg:w-18 lg:h-22 stroke-white" />
            <p className="hidden lg:flex font-bold text-xs-1 lg:text-md-4 text-center">
              Click and drag to navigate the map
            </p>
            <p className="flex lg:hidden font-bold text-xs-1 lg:text-md-4 text-center">
              Pinch and drag to navigate the map
            </p>
          </div>
        )}
        {/* Hovering over region */}
        {hasInteracted && activeHoverRegion !== '' && (
          <div className="absolute inset-0 flex flex-col justify-center items-center pointer-events-none text-white p-4 bg-black/30">
            <p className="font-display text-md-1 lg:text-2xl-1 text-center leading-none">
              {activeHoverRegion}
            </p>
          </div>
        )}
      </div>
    </section>
  )
}

export default CharityMap

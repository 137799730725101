import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const Page = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicPage
  const page = pageContent.data || {}

  const seo = {
    title: page.seo_title.text || null,
    description: page.seo_description.text || null,
  }

  return (
    <Layout title={seo.title} description={seo.description}>
      <SliceZone slices={page.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String) {
    prismicPage(id: { eq: $id }) {
      _previewable
      url
      uid
      type
      id
      data {
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PageDataBodyExtraMileBanner
          ...PageDataBodyFullWidth2Col
        }
        seo_description {
          text
        }
        seo_title {
          text
        }
      }
    }
  }
`

export default withPrismicPreview(Page)

import { PrismicRichText } from '@prismicio/react'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

const Footer = () => {
  const query = useStaticQuery(graphql`
    {
      prismicFooter {
        data {
          logos {
            logo {
              url
              alt
            }
            link {
              url
            }
          }
          text {
            richText
          }
        }
      }
    }
  `)

  const queryData = query.prismicFooter.data

  const logos = queryData.logos || []
  const footerText = queryData.text

  return (
    <footer className="flex flex-col w-full mt-auto">
      {/* Full width racer images */}
      <div className="w-full bg-dark-green pb-6 pt-4 lg:pb-12">
        <img
          src={`/images/desktop-racers.png`}
          alt="Racers"
          loading="lazy"
          className="hidden lg:flex w-full object-cover"
        />
        <img
          src={`/images/mobile-racers.png`}
          alt="Racers"
          loading="lazy"
          className="flex lg:hidden w-full object-cover"
        />
      </div>
      {/* Logos */}
      <div className="grid grid-cols-2 lg:grid-cols-none lg:grid-flow-col gap-x-12 gap-y-8 bg-white px-4 py-12 lg:container lg:mx-auto lg:px-4 lg:py-20">
        {logos.map(({ logo, link }) => (
          <a
            href={link.url}
            target="_blank"
            rel="noreferrer"
            key={logo.url}
            className=""
          >
            <img
              src={logo.url}
              alt={logo.alt}
              loading="lazy"
              className="object-contain object-center even:justify-self-end odd:justify-self-start lg:justify-self-auto max-h-18"
            />
          </a>
        ))}
      </div>
      {/* Blue footer strip */}
      <div className="bg-dark-blue flex flex-col space-y-4 px-4 pt-4 pb-5 lg:space-y-0 lg:flex-row lg:justify-between lg:px-6 lg:py-4 text-white">
        {/* Top/Left Text */}
        <div className="font-bold leading-4 text-xs-0.5 tracking-tight lg:leading-tight lg:text-xs-2 lg:max-w-md xl:text-sm-1 xl:max-w-2xl xl:leading-6">
          <PrismicRichText field={footerText.richText} />
        </div>
        {/* Bottom/Right Text */}
        <div className="flex flex-col text-[11px] leading-3 space-y-1 lg:text-xs-1 xl:text-xs-2 lg:space-y-0 lg:text-right lg:items-end lg:leading-normal">
          <ul className="flex flex-row flex-wrap tracking-tighter">
            <li>
              South Western Ambulance Charity | Abbey Court, Eagle Way, Exeter,
              EX2 7HY
            </li>
          </ul>
          <ul className="flex space-x-2">
            <li>
              Tel:&nbsp;
              <a
                className="hover:underline underline-offset-2"
                href="tel:03003690108"
              >
                0300 3690108
              </a>
            </li>
            <li>
              Email:&nbsp;
              <a
                className="hover:underline underline-offset-2"
                href="mailto:charity@swast.nhs.uk"
              >
                charity@swast.nhs.uk
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import { PrismicRichText } from '@prismicio/react'
import { graphql } from 'gatsby'
import React from 'react'

export const FullWidth2Col = ({ slice }) => {
  return (
    <section className="full-width-2-col bg-dark-green relative pt-4 pb-10 text-white lg:py-0 cms-content">
      <div className="flex flex-col">
        {/* Title */}
        <div className="text-center text-md-1 font-display lg:text-lg-1 leading-none ">
          <PrismicRichText field={slice.primary.block_title.richText} />
        </div>
        <div className="bg-dark-green pt-9 lg:pt-12 lg:pb-10">
          <div className="container px-4 mx-auto grid grid-cols-1 gap-y-6 lg:grid-cols-2 lg:gap-x-24">
            {/* Text Block 1 */}
            <div className="flex flex-col space-y-4 text-xs-1 lg:text-sm-1">
              <PrismicRichText field={slice.primary.block_content_1.richText} />
            </div>
            {/* Text Block 2 */}
            <div className="flex flex-col space-y-4">
              <PrismicRichText field={slice.primary.block_content_2.richText} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyFullWidth2Col on PrismicPageDataBodyFullWidth2Col {
    primary {
      block_content_1 {
        richText
      }
      block_content_2 {
        richText
      }
      block_title {
        richText
      }
    }
  }
  fragment HomeDataBodyFullWidth2Col on PrismicHomeDataBodyFullWidth2Col {
    primary {
      block_content_1 {
        richText
      }
      block_content_2 {
        richText
      }
      block_title {
        richText
      }
    }
  }
`
